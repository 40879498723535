.mainCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 150px;
    width: 100%;
    left: 0px;
    padding-bottom: 80px;
}
.title {
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-top: 50px;
}
.miniLineCol {
    width: 40px;
    background-color: rgba(245, 0, 87, 1);
    /* background-color: rgba(0,0,0,0.8); */
    margin: auto;
    margin-bottom: 0px;
    margin-top: 20px;
    border-radius: 3px;
    height: 8px;
}
.details {
    font-size: 16px;
    margin: auto;
    text-align: center;
    margin-top: 20px;
    width: 60%;
}
.ruleCont,
.challengesCont,
.rankingCont {
    width: 100%;
    margin: auto;
}
.subTitle {
    font-weight: 600;
    font-size: 35px;
    margin: auto;
    margin-top: 40px;
}
.miniLine {
    width: 40px;
    background-color: rgba(63, 81, 181, 0.9);
    /* background-color: rgba(0,0,0,0.8); */
    margin-top: 20px;
    margin-left: 10px;
    border-radius: 3px;
    height: 8px;
}
.ruleDetails {
    margin: auto;
    margin-top: 20px;
    width: 98%;
}
