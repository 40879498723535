.mainCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* background-color: red; */
    justify-content: flex-start;
    position: absolute;
    top: 145px;
    height: calc(100% - 145px);
    width: calc(100% - 200px);
    left: 200px;
}
.innerCont {
    width: 80%;
    margin: auto;
}
.pageDetCont {
    margin-left: 80px;
    margin-top: 80px;
    margin-right: 80px;
}
.pageTitle {
    font-size: 50px;
    font-weight: 700;
}
.miniLine {
    width: 40px;
    height: 8px;
    background-color: rgba(63, 81, 181, 0.9);
    /* background-color: rgba(0,0,0,0.8); */
    margin-top: 25px;
    border-radius: 3px;
}
.tableCont {
    margin-top: 20px;
}
.tableFieldTitle {
    font-size: 18px;
    font-weight: 600;
}
.tableProbs {
    margin-top: 20px;
}
.btnCont {
    margin-top: 50px;
}
