.mainCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* background-color: red; */
    justify-content: flex-start;
    position: absolute;
    top: 150px;
    height: calc(100% - 150px);
    width: 100%;
    left: 0px;
}
.contactDetCont {
    margin-left: 80px;
    margin-top: 80px;
    margin-right: 80px;
}
.contactDetTitle {
    font-size: 50px;
    font-weight: 700;
}
.miniLine {
    width: 40px;
    height: 8px;
    background-color: rgba(63, 81, 181, 0.9);
    /* background-color: rgba(0,0,0,0.8); */
    margin-top: 25px;
    border-radius: 3px;
}
.contactDetDesc {
    margin-top: 30px;
    font-size: 16px;
}
.iconCont {
    background-color: #e6e6e6;
    border-radius: 10px;
    padding: 15px;
    margin-top: 30px;
    width: 240px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: row;
}
.icon {
    border: 2px solid black;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 30px;
    cursor: pointer;
    transition: 0.2s ease-out;
    text-decoration: none;
    color: black;
    border-radius: 10px;
}
.icon:hover {
    background-color: black;
    color: white;
}
.extra {
    margin-top: 50px;
    font-style: italic;
}
