.title {
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}
.tableTitle {
    font-size: 17px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
}
.column1 {
    display: flex;
    flex-direction: row;
}
.checkBox {
    margin-right: 10px;
}
