.body {
    margin: 0px;
    padding: 0px;
}

.center {
    display: flex;
    justify-content: center;
}

nav {
    position: fixed;
    top: 100px;
    left: 0px;
    width: 200px;
    height: 100%;
    background: #151719;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.catList {
    color: rgba(230, 230, 230, 0.9);
    list-style: none;
    padding: 65px 10px;
    border-bottom: 1px solid rgba(100, 100, 100, 0.3);
    font-size: 20px;
    cursor: pointer;
}
.catList:hover {
    color: blue;
}

main {
    margin-left: 220px;
}

a {
    text-decoration: none;
}
