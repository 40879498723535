.cardTitle {
    font-weight: 700;
    font-size: 40px;
    text-transform: uppercase;
}
.miniLineCenter {
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    height: 8px;
    width: 40px;
    background-color: rgba(63, 81, 181, 0.9);
    /* background-color: rgba(0,0,0,0.8); */
    border-radius: 3px;
}
.competitionList {
    width: auto;
    height: auto;
    /* background-color: red; */
    margin-top: 20px;
    /* padding: 20px; */
}
.competitionLink {
    font-style: none;
    text-decoration: none;
    color: black;
    padding: 20px;
    width: auto;
    font-size: 20px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
}
.competitionLink:hover {
    background-color: rgba(245, 0, 87, 1);
    color: white;
}
