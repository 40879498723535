.mainCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 150px;
    width: 100%;
    left: 0px;
    padding-bottom: 80px;
}
.pageTitle {
    font-weight: 700;
    font-size: 50px;
    margin-top: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.miniLine {
    width: 40px;
    background-color: rgba(63, 81, 181, 0.9);
    /* background-color: rgba(0,0,0,0.8); */
    margin: auto;
    margin-top: 25px;
    border-radius: 3px;
    height: 8px;
}
.competitionListCont {
    /* background-color: ; */
    border: 2px solid rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    width: 80%;
    display: flex;
    padding: 50px;
    flex-direction: column;
    margin-top: 60px;
    padding-top: 10px;
}
.competitionCard {
    padding: 20px;
    margin-top: 40px;
}
.competitionCardTitle {
    color: black;
    font-weight: 600;
    font-size: 40px;
    transition: 0.2s ease-out;
}
.competitionCardTitle:hover {
    letter-spacing: 2px;
}
.miniLineCol {
    width: 40px;
    background-color: rgba(245, 0, 87, 1);
    /* background-color: rgba(0,0,0,0.8); */
    margin-top: 15px;
    margin-left: 5px;
    border-radius: 3px;
    height: 8px;
}
.competitionCardDetailCont {
    width: calc(100% - 20px);
    margin-top: 5px;
    font-size: 15px;
    /* background-color: red; */
    padding: 10px;
}
.thinLine {
    width: 40px;
    background-color: rgba(63, 81, 181, 0.9);
    /* background-color: rgba(0,0,0,0.8); */
    margin-top: 20px;
    border-radius: 3px;
    height: 4px;
}
.competitionCardExtras {
    margin-top: 20px;
    padding: 15px;
    font-weight: 300;
    font-size: 18px;
    border: 1px solid rgba(0, 0, 0, 0.8);
}
.btnCont {
    margin-top: 30px;
}
